import { Field } from '@tm/shared-lib/src/field'
import { Translation } from '@tm/shared-lib/src/i18n'
import { useMemo } from 'react'

export const ReviewFieldNames = [
  'comment',
  'comment_video',
  'name',
  'location',
  'organization',
  'imageFilename',
  'position',
  'team',
  'companyLogo',
  'website',
  'neighborhood',
  'state',
  'country',
  'sources',
  'noPublicConsent',
  'noVideoRecord',
] as const
export const ContactInfoFieldNames = [
  'name',
  'location',
  'organization',
  'imageFilename',
  'position',
  'team',
  'companyLogo',
  'website',
  'neighborhood',
  'state',
  'country',
] as const

export type ReviewFieldName = typeof ReviewFieldNames[number]
export type ContactInfoFieldName = typeof ContactInfoFieldNames[number]

export const isContactInfoFieldName = (name: string): name is ContactInfoFieldName =>
  ContactInfoFieldNames.includes(name as ContactInfoFieldName)

export const isReviewFieldName = (name: string): name is ReviewFieldName => ReviewFieldNames.includes(name as ReviewFieldName)

export type ReviewFields = Map<ReviewFieldName, Field>
export type ContactInfoFields = Map<ContactInfoFieldName, Field>

export function useReviewFields(stepFields: Field[]) {
  return useMemo(() => {
    let contactInfoLabel: Translation<string> | null = null
    const reviewFields: ReviewFields = new Map()
    for (const field of stepFields) {
      if (isReviewFieldName(field.name)) reviewFields.set(field.name, field)

      if (field.type === 'fieldset' && field.contactFields) {
        if (field.label) contactInfoLabel = field.label

        const contactFields = field.fields || []
        for (const field of contactFields) {
          if (isReviewFieldName(field.name)) reviewFields.set(field.name, field)
        }
      }
    }

    return {
      reviewFields,
      contactInfoLabel,
    }
  }, [stepFields])
}
