import { SkipButton } from '../Review/SkipButton'
import { useReviewFields } from '../Review/useReviewFields'
import { useSkipButton } from '../Review/useSkipButton'
import { ViewProps } from '../types'

export function ReviewSkipButton(props: ViewProps) {
  const { reviewFields } = useReviewFields(props.fields)
  const skipButtonState = useSkipButton(props, reviewFields)
  return <SkipButton {...skipButtonState} />
}
