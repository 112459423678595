import Stack from '@mui/material/Stack'
import { CampaignSubmit } from '../../CampaignSubmit'
import { isSkipFieldName } from '../Review/useSkipButton'
import { ViewProps } from '../types'
import { RenderField } from './RenderField'
import { ReviewSkipButton } from './ReviewSkipButton'

export function DefaultView(props: ViewProps) {
  const { fields, submitForm, isSubmitting } = props

  /**
   * Check if skipField (noPublicConsent or noVideoRecord) exists
   * and if does, filter out from list and render skip button below submit
   *
   * Only supports first field
   */
  const skipFieldIndex = fields.findIndex(field => isSkipFieldName(field.name))

  return (
    <Stack gap={3}>
      {fields
        // Don't include noPublicConsent field in normal view
        .filter((_, index) => skipFieldIndex !== index)
        .map((field, index) => (
          <RenderField key={`${index}-${field.name}`} field={field} {...props} />
        ))}
      <CampaignSubmit onClick={submitForm} disabled={isSubmitting} />
      {skipFieldIndex !== -1 && <ReviewSkipButton {...props} />}
    </Stack>
  )
}
