import { Field } from '@tm/shared-lib/src/field'
import { ValidationErrors } from '@tm/shared-lib/src/validation'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { ThirdPartyCollect } from '../survey/thanks/ThirdPartyCollect'
import { CheckboxQuestion } from './Checkbox'
import { CheckboxesQuestion } from './Checkboxes'
import { CommentQuestion } from './Comment'
import { ContentQuestion } from './Content'
import { CsatQuestion } from './Csat'
import { EmailQuestion } from './Email'
import { ImageQuestion } from './Image'
import { NpsQuestion } from './Nps'
import { PrivacyPolicyQuestion } from './PrivacyPolicy'
import { PublicConsentQuestion } from './PublicConsent'
import { RadiosQuestion } from './Radios'
import { RangeQuestion } from './Range'
import { StarsQuestion } from './Stars'
import { TextAreaQuestion } from './TextArea'
import { TextFieldQuestion } from './TextField'
import { Video } from './video/Video'
import { WidgetEmbed } from './WidgetEmbed'

export interface QuestionProps {
  field: Field
  children?: JSX.Element
  validationErrors?: ValidationErrors
}

const QuestionField = styled('div')(({ theme }) => theme.styles.fieldWrapper)
export const FieldLabel = styled('label')(({ theme }) => theme.styles.fieldLabel)
export const DescriptionDiv = styled('div')(({ theme }) => theme.styles.fieldDescription)
const FieldLabelRequired = styled('span')(({ theme }) => theme.styles.fieldLabelRequired)
const FieldErrorsWrapper = styled('div')(({ theme }) => theme.styles.fieldErrorsWrapper)
const FieldError = styled('div')(({ theme }) => theme.styles.fieldError)

export const NonDropdownQuestion = (props: { field: QuestionProps['field'] }) => {
  const { lang } = useI18N()
  switch (props.field.type) {
    case 'nps':
      return <NpsQuestion field={props.field} />
    case 'csat':
      return <CsatQuestion field={props.field} />
    case 'textfield':
      return <TextFieldQuestion field={props.field} />
    case 'email':
      return <EmailQuestion field={props.field} />
    case 'checkbox':
      return <CheckboxQuestion field={props.field} />
    case 'checkboxes':
      return <CheckboxesQuestion field={props.field} />
    case 'radios':
      return <RadiosQuestion field={props.field} />
    case 'stars':
      return <StarsQuestion field={props.field} />
    case 'comment':
      return <CommentQuestion field={props.field} />
    case 'range':
      return <RangeQuestion field={props.field} />
    case 'content':
      return <ContentQuestion field={props.field} />
    case 'public_consent':
      return (
        <PublicConsentQuestion
          field={props.field}
          //companyName="(TODO: companyName)"
          //privacyPolicy="(TODO: privacyPolicy)"
        />
      )
    case 'textarea':
      return <TextAreaQuestion field={props.field} />
    case 'image':
      return <ImageQuestion field={props.field} />
    case 'video':
      return <Video field={props.field} />
    case 'widget':
      return <WidgetEmbed field={props.field} />
    case 'privacy_policy':
      return <PrivacyPolicyQuestion field={props.field} />
    case 'third-party-collect':
      return <ThirdPartyCollect language={lang} value={{ sources: props.field.sources }} />
    default:
      return null
  }
}

export function QuestionHolder({ field, children, validationErrors = {} }: QuestionProps): JSX.Element {
  const { t, lang } = useI18N()

  const path = field.system ? field.name : 'custom.' + field.name
  const errors = validationErrors[path] || []

  const classNames = ['tm-field', `tm-field--${field.type}`]
  if (errors.length) classNames.push('tm-field--error')
  let labelParts: string[] = []
  if ('label' in field) {
    //Ignore label for content field
    if (field.type !== 'content') {
      classNames.push('tm-field--label')
      labelParts = field.label && field.label[lang] ? field.label[lang].split('\n') : []
    }
  } else if ('labelKey' in field && typeof field.labelKey === 'string') {
    labelParts = t(field.labelKey).split('\n')
  }
  let descriptionParts: string[] = []
  if (field.description && field.description[lang]) {
    descriptionParts = field.description[lang].split('\n')
  } else if (field.descriptionKey) {
    descriptionParts = t(field.descriptionKey).split('\n')
  }
  //const descriptionParts = field.description && field.description[lang] ? field.description[lang].split('\n') : []
  if ('style' in field && field.style === 'footnote') {
    classNames.push('tm-field--footnote')
  }
  const isRequired = Array.isArray(field.validation) && field.validation.includes('required')

  return (
    <QuestionField className={classNames.join(' ')}>
      {!!labelParts.length && (
        <FieldLabel
          className={`tm-field-label tm-field-label--${field.type}`}
          htmlFor={`tm-${field.name}`}
          title={isRequired ? t('validation.required') : ''}>
          {labelParts.map((s, k) => (
            <div key={k}>
              {s}
              {k === labelParts.length - 1 && isRequired ? (
                <FieldLabelRequired className="tm-field-label-required">*</FieldLabelRequired>
              ) : null}
            </div>
          ))}
        </FieldLabel>
      )}
      {!!descriptionParts.length && (
        <DescriptionDiv className="tm-field-description">
          {descriptionParts.map((s, k) => (
            <div key={k}>{s || ' '}</div>
          ))}
        </DescriptionDiv>
      )}
      {children}
      {errors.length > 0 && (
        <FieldErrorsWrapper className="tm-field-errors">
          {errors.map(errorKey => (
            <FieldError className="tm-field-error" key={errorKey}>
              {t(errorKey)}
            </FieldError>
          ))}
        </FieldErrorsWrapper>
      )}
    </QuestionField>
  )
}
