import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { Field } from '@tm/shared-lib/src/field'
import { useCallback, useEffect, useState } from 'react'
import { useSurveyValue } from '../../../../hooks/useSurveyValue'
import { ViewProps } from '../types'
import { NpsQuestionView } from './NpsQuestionView'
import { useShouldSubmit } from './useShouldSubmit'

/**
 * Nps question loader will check how NpsQuestionView should be rendered
 * and updates Survey context accordingly
 */

type NpsQuestionLoaderInnerProps = {
  field: Field
  lang: string
  submitForm: () => void
  isSubmitting: boolean
}

function NpsQuestionLoaderInner({ field, lang, submitForm, isSubmitting }: NpsQuestionLoaderInnerProps) {
  const [value, setValue] = useSurveyValue(field)
  const label = 'label' in field && field.label ? field.label[lang] : undefined
  const { showSubmit, setShouldSubmit } = useShouldSubmit({ value, submitForm })

  useTitle(label)

  const onNpsChange = useCallback(
    (nextValue: number | null) => {
      setValue(nextValue)
      if (showSubmit) return
      setShouldSubmit(true)
    },
    [setValue, showSubmit, setShouldSubmit]
  )

  return (
    <NpsQuestionView
      value={typeof value === 'number' ? value : undefined}
      label={label}
      onChange={onNpsChange}
      name={field.name}
      disabled={isSubmitting}
      showSubmit={showSubmit}
      onSubmit={() => setShouldSubmit(true)}
    />
  )
}

export function NpsQuestionLoader({ state, submitForm, isSubmitting }: ViewProps) {
  const fields = state.step.fields || []
  const npsField = fields.find(field => field.type === 'nps')
  if (!npsField) return null
  return (
    <NpsQuestionLoaderInner field={npsField} lang={state.lang} submitForm={submitForm} isSubmitting={isSubmitting} />
  )
}
