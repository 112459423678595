import { CheckboxField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import React from 'react'
import useI18N from '../../hooks/useI18n'
import { useOtherOptions } from '../../hooks/useOtherOptions'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { stringValue } from '../campaign/view/Default/inputs/types'
import { Checkbox } from '../field/Checkbox'
import { InputField } from '../field/InputField'

interface Props {
  field: CheckboxField
}

const Divider = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export function CheckboxQuestion({ field }: Props): JSX.Element {
  const { lang } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const [offValue, setOffValue] = useSurveyValue({ name: `${field.name}|not-checked` })
  const [onValue, setOnValue] = useSurveyValue({ name: `${field.name}|checked` })
  if (typeof value === 'string' && (value === 'true' || value === 'false' || value === '')) {
    return (
      <Divider>
        <Checkbox value={value === 'true'} onValueChange={setValue} {...field} />
        {(field.otherOption === 'not-checked' && value === 'false') ||
        (field.otherOption === 'checked' && value === 'true') ? (
          <InputField
            placeholder={field.otherLabel?.[lang]}
            value={field.otherOption === 'not-checked' ? stringValue(offValue) : stringValue(onValue)}
            onValueChange={field.otherOption === 'not-checked' ? setOffValue : setOnValue}
          />
        ) : null}
      </Divider>
    )
  } else if (value === undefined || typeof value === 'boolean') {
    return (
      <Divider>
        <Checkbox value={value} onValueChange={setValue} {...field} />
        {(field.otherOption === 'not-checked' && !value) || (field.otherOption === 'checked' && value) ? (
          <InputField
            placeholder={field.otherLabel?.[lang]}
            value={field.otherOption === 'not-checked' ? stringValue(offValue) : stringValue(onValue)}
            onValueChange={field.otherOption === 'not-checked' ? setOffValue : setOnValue}
          />
        ) : null}
      </Divider>
    )
  }
  throw new Error('Checkbox does not support value: ' + value)
}
