import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { ViewProps } from '../types'
import { ThanksView } from './ThanksView'

type ThanksLoaderProps = {
  state: ViewProps['state']
  t: ViewProps['t']
}

export function ThanksLoader({ state, t }: ThanksLoaderProps) {
  const { thanksPage = {}, lang } = state
  const title = thanksPage.thanks?.title?.[lang] || t('thanks.title')
  const text = thanksPage.thanks?.text?.[lang] || t('thanks.text')
  const thirdPartySources = thanksPage.thanks?.thirdPartyCollect?.sources || []
  const thirdPartyEnabled = thanksPage.thanks?.thirdPartyCollect?.enabled || false
  const autoRedirectEnabled = thanksPage.thanks?.thirdPartyCollect?.autoRedirect || false

  useTitle(title)

  return (
    <ThanksView
      title={title}
      text={text}
      sources={thirdPartyEnabled ? thirdPartySources : undefined}
      lang={lang}
      autoRedirectEnabled={autoRedirectEnabled}
    />
  )
}
