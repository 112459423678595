import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { useOtherOptions } from '@tm/client-form/src/hooks/useOtherOptions'
import { CheckboxesField } from '@tm/shared-lib/src/field'
import { useCallback } from 'react'
import { getTranslatedValue } from './getTranslatedValue'
import { arrayValue, InputProps } from './types'

export function CheckboxesInput(props: InputProps<CheckboxesField>) {
  const { field, onChange, isSubmitting, lang } = props
  const value = arrayValue(props.value)
  const { otherOptionValues, otherOptionLabels, setOptionValue, otherOptionByOptionId } = useOtherOptions(
    field.name,
    field.options
  )
  const otherOptions = value.filter(value => otherOptionByOptionId[value])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updateValue = (event.target as HTMLInputElement).value
      onChange(value.includes(updateValue) ? value.filter(v => v !== updateValue) : [...value, updateValue])
    },
    [onChange, value]
  )

  return (
    <>
      <FormControl>
        {field.options?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Checkbox />}
            label={getTranslatedValue(option.label, lang)}
            disabled={isSubmitting}
            checked={value.includes(option.value)}
            onChange={handleChange}
          />
        ))}
      </FormControl>
      {otherOptions.map(optionId => (
        <TextField
          value={otherOptionValues[optionId]}
          onChange={event => {
            setOptionValue(event.target.value, optionId)
          }}
          label={getTranslatedValue(otherOptionLabels[optionId], lang)}
          disabled={isSubmitting}
          fullWidth
          variant="filled"
          key={`f-${field.name}-${optionId}`}
        />
      ))}
    </>
  )
}
