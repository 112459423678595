import { CampaignContent } from '@tm/client-form/src/components/campaign/CampaignContent'
import { ContentField } from '@tm/shared-lib/src/field'

type ContentInputProps = {
  field: ContentField
  lang: string
}

export function ContentInput({ field, lang }: ContentInputProps) {
  if (!field.content) return null
  const content = field.content[lang] || field.content._d
  return <CampaignContent content={content} />
}
