import { CheckboxesField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import React from 'react'
import useI18N from '../../hooks/useI18n'
import { useOtherOptions } from '../../hooks/useOtherOptions'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'
import { InputField } from '../field/InputField'

const Divider = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .tm-input {
    margin-bottom: 8px;
  }
`

interface Props {
  field: CheckboxesField
}

export function CheckboxesQuestion({ field }: Props): JSX.Element {
  const [value = [], setValue] = useSurveyValue(field)
  const { otherOptionValues, setOptionValue } = useOtherOptions(field.name, field.options)
  const { lang } = useI18N()
  if (!Array.isArray(value)) {
    throw new Error('Checkboxes does not support value: ' + value)
  }
  const { options = [] } = field
  return (
    <div className="tm-input tm-input--checkboxes">
      {options.map(({ value: optionValue, label = {}, otherLabel = {}, isOtherOption = false }) => {
        const optionIndex = value.indexOf(optionValue)
        const isChecked = optionIndex >= 0
        const otherLabelStr = otherLabel[lang] || ''

        return (
          <Divider key={optionValue}>
            <Checkbox
              key={optionValue}
              inputLabel={label}
              value={isChecked}
              onValueChange={(v: boolean) => {
                const newValue: string[] = v
                  ? [...value, optionValue]
                  : [...value.slice(0, optionIndex), ...value.slice(optionIndex + 1)]
                setValue(newValue)
              }}
            />
            {isChecked && isOtherOption ? (
              <InputField
                placeholder={otherLabelStr}
                value={otherOptionValues[optionValue] as string}
                onValueChange={value => setOptionValue(value, optionValue)}
              />
            ) : null}
          </Divider>
        )
      })}
    </div>
  )
}
