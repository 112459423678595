import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { TranslateFunction, useI18N } from '@tm/client-form/src/hooks/useI18n'
import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'
import { CheckboxField } from '@tm/shared-lib/src/field'
import { useCallback } from 'react'
import { getTranslatedValue } from './getTranslatedValue'
import { booleanValue, InputProps } from './types'

const getCheckboxLabel = (field: CheckboxField, lang: string, t: TranslateFunction) => {
  // If inputLabelKey is defined, use it to get the label
  if (field.inputLabelKey) return t(field.inputLabelKey)

  return getTranslatedValue(field.inputLabel, lang)
}

export function CheckboxInput(props: InputProps<CheckboxField>) {
  const { field, onChange, isSubmitting, lang } = props
  const { t } = useI18N()
  const value = booleanValue(props.value)
  const label = getCheckboxLabel(field, lang, t)
  const [offValue, setOffValue] = useSurveyValue({ name: `${field.name}|not-checked` })
  const [onValue, setOnValue] = useSurveyValue({ name: `${field.name}|checked` })
  const otherOptionValues = {
    checked: onValue,
    'not-checked': offValue,
  }
  const otherOptionSetters = {
    checked: setOnValue,
    'not-checked': setOffValue,
  }

  const handleChange = useCallback(() => {
    onChange(!value)
  }, [onChange, value])

  return (
    <>
      <FormControl>
        <FormControlLabel
          control={<Checkbox />}
          label={label}
          disabled={isSubmitting}
          checked={value}
          onChange={handleChange}
        />
      </FormControl>
      {(field.otherOption === 'checked' && value) || (field.otherOption === 'not-checked' && !value) ? (
        <TextField
          value={otherOptionValues[field.otherOption]}
          onChange={event => {
            otherOptionSetters[field.otherOption as 'checked' | 'not-checked'](event.target.value)
          }}
          label={getTranslatedValue(field.otherLabel, lang)}
          disabled={isSubmitting}
          fullWidth
          variant="filled"
          key={`f-${field.name}-${field.otherOption}`}
        />
      ) : null}
    </>
  )
}
