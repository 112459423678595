import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { Field } from '@tm/shared-lib/src/field'
import { useCallback, useEffect, useState } from 'react'
import { useSurveyValue } from '../../../../hooks/useSurveyValue'
import { useShouldSubmit } from '../NpsQuestion/useShouldSubmit'
import { ViewProps } from '../types'
import { CsatQuestionView } from './CsatQuestionView'

/**
 * Csat question loader will check how CsatQuestionView should be rendered
 * and updates Survey context accordingly
 */

type CsatQuestionLoaderInnerProps = {
  field: Field
  lang: string
  submitForm: () => void
  isSubmitting: boolean
}

function CsatQuestionLoaderInner({ field, lang, submitForm, isSubmitting }: CsatQuestionLoaderInnerProps) {
  const [value, setValue] = useSurveyValue(field)
  const { showSubmit, setShouldSubmit } = useShouldSubmit({ value, submitForm })
  const label = 'label' in field && field.label ? field.label[lang] : undefined

  useTitle(label)

  const onCsatChange = useCallback(
    (nextValue: number | null) => {
      setValue(nextValue)
      if (showSubmit) return
      setShouldSubmit(true)
    },
    [setValue, showSubmit, setShouldSubmit]
  )

  return (
    <CsatQuestionView
      value={typeof value === 'number' ? value : undefined}
      label={label}
      onChange={onCsatChange}
      name={field.name}
      disabled={isSubmitting}
      showSubmit={showSubmit}
      onSubmit={() => setShouldSubmit(true)}
    />
  )
}

export function CsatQuestionLoader({ state, submitForm, isSubmitting }: ViewProps) {
  const fields = state.step.fields || []
  const csatField = fields.find(field => field.type === 'csat')
  if (!csatField) return null
  return (
    <CsatQuestionLoaderInner field={csatField} lang={state.lang} submitForm={submitForm} isSubmitting={isSubmitting} />
  )
}
