import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { useOtherOptions } from '@tm/client-form/src/hooks/useOtherOptions'
import { RadiosField } from '@tm/shared-lib/src/field'
import { useCallback } from 'react'
import { getTranslatedValue } from './getTranslatedValue'
import { InputProps, stringValue } from './types'

export function RadiosInput(props: InputProps<RadiosField>) {
  const { field, onChange, isSubmitting, lang } = props
  const { otherOptionValues, otherOptionLabels, setOptionValue, otherOptionByOptionId } = useOtherOptions(
    field.name,
    field.options
  )
  const value = stringValue(props.value)
  const otherOption = otherOptionByOptionId[value]

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange((event.target as HTMLInputElement).value)
    },
    [onChange]
  )

  return (
    <>
      <FormControl>
        <RadioGroup name={field.name} value={value} onChange={handleChange}>
          {field.options?.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={getTranslatedValue(option.label, lang)}
              disabled={isSubmitting}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {otherOption && (
        <TextField
          value={otherOptionValues[value]}
          onChange={event => {
            setOptionValue(event.target.value, value)
          }}
          label={getTranslatedValue(otherOptionLabels[value], lang)}
          disabled={isSubmitting}
          fullWidth
          variant="filled"
          key={`f-${field.name}-${value}`}
        />
      )}
    </>
  )
}
