import { PrivacyPolicyField } from '@tm/shared-lib/src/field'
import React, { useMemo } from 'react'
import { useI18N } from '../../hooks/useI18n'
import { State } from '../../hooks/useSurvey/states/state'
import { useSurveyState } from '../../hooks/useSurveyState'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'

interface Props {
  field: PrivacyPolicyField
}

export const getPrivacyPolicyAndOrganization = (
  state: State
): {
  privacyPolicy: string
  privacyPolicyOrganization: string
} => {
  if (state.type !== 'in progress')
    return {
      privacyPolicy: '',
      privacyPolicyOrganization: '',
    }

  let privacyPolicy = state.meta.privacyPolicy || ''
  const privacyPolicyOrganization = state.meta.privacyPolicyOrganization || ''

  // Override privacy policy with translation only if is not main lang
  // In editor we show that mainlang has main privacy policy and non main langs have translations
  const isMainLang = state.meta.languages.find(item => item.lang === state.lang)?.main
  const translations = state.meta.privacyPolicyTranslations

  if (!isMainLang && translations && translations[state.lang]?.privacyPolicy.trim()) {
    privacyPolicy = translations[state.lang].privacyPolicy
  }

  return {
    privacyPolicy,
    privacyPolicyOrganization,
  }
}

export function PrivacyPolicyQuestion({ field }: Props): JSX.Element {
  const { t } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const [state] = useSurveyState()
  const { privacyPolicy, privacyPolicyOrganization } = getPrivacyPolicyAndOrganization(state)

  const booleanValue = useMemo(() => {
    if (typeof value === 'string' && (value === 'true' || value === 'false' || value === '')) {
      return value === 'true'
    } else if (value === undefined || typeof value === 'boolean') {
      return value
    }
    return undefined
  }, [value])

  return (
    <Checkbox
      value={booleanValue}
      onValueChange={setValue}
      renderLabel={() => (
        <>
          {`${t('privacyPolicy.label')} ${privacyPolicyOrganization} `}
          {privacyPolicy ? (
            <a href={privacyPolicy} target="_blank" rel="noreferrer">
              {t('publicConsent.privacyPolicy')}
            </a>
          ) : (
            t('publicConsent.privacyPolicy')
          )}
        </>
      )}
    />
  )
}
