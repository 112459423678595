import { PublicConsentField } from '@tm/shared-lib/src/field'
import { ConsentField } from '../../../ConsentField/ConsentField'
import { useConsentField, UseConsentProps } from '../../../ConsentField/useConsentField'
import { InputProps } from './types'

export function PublicConsentInput(props: InputProps<PublicConsentField> & UseConsentProps) {
  const { field, state, dispatch, validationErrors } = props
  const consentProps = useConsentField({ state, dispatch, validationErrors }, field.name)

  return <ConsentField {...consentProps} />
}
