import { SxProps } from '@mui/material/styles'
import { CampaignFrameState, useCampaignFrame } from '@tm/client-form/src/hooks/useCampaignFrame'
import { MetaData } from '@tm/shared-lib/src/survey'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { isLight } from '../misc/getBrightness'

const titleSuffix = 'Trustmary Form'

export type CampaignValues = {
  pageBackgroundColor: string
  primaryColor: string
  outsideTextColor: string
  primaryTextColor: string
  logo: string | null
  headerText: string | null
  loading: boolean
  whitelabel: boolean
  setLoading: (loading: boolean) => void
  title: string
  setTitle: (title: string) => void
  campaignFrame: CampaignFrameState
  buttonSx: SxProps
  editor: boolean
}

const defaultCampaignValues: CampaignValues = {
  pageBackgroundColor: '#161c25',
  primaryColor: '#161c25',
  outsideTextColor: '#000',
  primaryTextColor: '#fff',
  logo: null,
  headerText: null,
  loading: false,
  whitelabel: false,
  setLoading: () => {
    /**/
  },
  title: titleSuffix,
  setTitle: () => {
    /**/
  },
  campaignFrame: {
    inFrame: false,
    frameId: '',
    frameView: 'compact',
    onResize: () => {
      /**/
    },
  },
  buttonSx: {
    backgroundColor: '#0d3b70',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0d3b70',
    },
    '&:active': {
      backgroundColor: '#0d3b70',
    },
  },
  editor: false,
}

export const CampaignContext = createContext<CampaignValues>(defaultCampaignValues)

type CampaignProviderProps = {
  meta?: MetaData
  children: React.ReactNode
  updateTitle?: boolean
  editor?: boolean
}

const getOutsideTextColor = (color: string) => {
  return isLight(color) ? '#000' : '#fff'
}

export const CampaignProvider = ({ meta, children, updateTitle = true, editor = false }: CampaignProviderProps) => {
  const [loading, setLoading] = useState(false)
  const [title, setTitleState] = useState(titleSuffix)
  const campaignFrame = useCampaignFrame()

  const setTitle = useCallback(
    (nextTitle: string) => {
      setTitleState(nextTitle.trim() ? `${nextTitle} - ${titleSuffix}` : titleSuffix)
    },
    [setTitleState]
  )

  // Update title to html title
  useEffect(() => {
    if (!updateTitle) return
    document.title = title
  }, [title, updateTitle])

  const values: CampaignValues = useMemo(() => {
    const primaryColor = meta?.theme?.primaryColor || defaultCampaignValues.primaryColor
    // const pageBackgroundColor = meta?.theme?.pageBackgroundColor || defaultCampaignValues.pageBackgroundColor
    const pageBackgroundColor = primaryColor
    const surveyWhitelabeling = meta?.whitelabel || defaultCampaignValues.whitelabel
    const primaryTextColor = getOutsideTextColor(primaryColor)

    return {
      pageBackgroundColor,
      primaryColor,
      logo: meta?.logo || defaultCampaignValues.logo,
      headerText: meta?.companyName || defaultCampaignValues.headerText,
      outsideTextColor: getOutsideTextColor(pageBackgroundColor),
      primaryTextColor,
      buttonSx: {
        backgroundColor: primaryColor,
        color: primaryTextColor,
        '&:hover': {
          backgroundColor: primaryColor,
        },
        '&:active': {
          backgroundColor: primaryColor,
        },
      },
      loading,
      whitelabel: surveyWhitelabeling,
      setLoading,
      title,
      setTitle,
      campaignFrame,
      editor,
    }
  }, [meta, loading, setLoading, title, setTitle, campaignFrame, editor])

  return <CampaignContext.Provider value={values}>{children}</CampaignContext.Provider>
}
