import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useOtherOptions } from '@tm/client-form/src/hooks/useOtherOptions'
import { DropdownField, MultidropdownField } from '@tm/shared-lib/src/field'
import { getTranslatedValue } from './getTranslatedValue'
import { arrayValue, InputProps, stringValue } from './types'

export function DropdownInput(props: InputProps<DropdownField | MultidropdownField>) {
  const { field, value, onChange, isSubmitting, lang } = props
  const isMultiple = field.type === 'multidropdown'
  const { otherOptionValues, otherOptionLabels, setOptionValue, otherOptionByOptionId } = useOtherOptions(
    field.name,
    field.options
  )
  const otherOptions = isMultiple
    ? arrayValue(value).filter(value => otherOptionByOptionId[value])
    : [stringValue(value)].filter(value => otherOptionByOptionId[value])

  const label = getTranslatedValue(field.label, lang)

  return (
    <>
      <TextField
        value={isMultiple ? arrayValue(value) : stringValue(value, '')}
        onChange={event => {
          onChange(event.target.value)
        }}
        disabled={isSubmitting}
        fullWidth
        variant="filled"
        key={`f-${field.name}`}
        hiddenLabel={!label}
        label={label}
        SelectProps={{
          multiple: field.type === 'multidropdown',
        }}
        select>
        {field.options?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {getTranslatedValue(option.label, lang)}
          </MenuItem>
        ))}
      </TextField>
      {otherOptions.map(optionId => (
        <TextField
          value={otherOptionValues[optionId]}
          onChange={event => {
            setOptionValue(event.target.value, optionId)
          }}
          label={getTranslatedValue(otherOptionLabels[optionId], lang)}
          disabled={isSubmitting}
          fullWidth
          variant="filled"
          key={`f-${field.name}-${optionId}`}
        />
      ))}
    </>
  )
}
