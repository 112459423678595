import React from 'react'
import { DropdownQuestion } from './Dropdown'
import { FieldSetQuestion } from './Fieldset'
import { MultidropdownQuestion } from './Multidropdown'
import { NonDropdownQuestion, QuestionHolder, QuestionProps } from './QuestionHolder'

export function QuestionFieldInner(props: QuestionProps): JSX.Element {
  switch (props.field.type) {
    case 'multidropdown':
      return <MultidropdownQuestion field={props.field} />
    case 'dropdown':
      return <DropdownQuestion field={props.field} />
    case 'fieldset':
      return (
        <FieldSetQuestion
          field={props.field}
          //To avoid circular dependency:
          questionRenderer={fieldsetField => (
            <Question key={fieldsetField.name} field={fieldsetField} validationErrors={props.validationErrors} />
          )}
        />
      )
    default:
      return <NonDropdownQuestion field={props.field} />
  }
}

export const Question = (props: QuestionProps) =>
  props.field.hidden ? null : (
    <QuestionHolder {...props}>
      <QuestionFieldInner {...props} />
    </QuestionHolder>
  )
