import { CustomValue } from '@tm/shared-lib/src/api'
import { WithOptions } from '@tm/shared-lib/src/field'
import { useMemo } from 'react'
import { useSurveyState } from './useSurveyState'

export function useOtherOptions(fieldId: string, options: WithOptions['options']) {
  const [state, dispatch] = useSurveyState()

  return useMemo(() => {
    const otherOptionByOptionId = Object.fromEntries(
      (options || []).map(option => [option.value, option.isOtherOption])
    )
    const otherOptionValues =
      'getValue' in state
        ? Object.fromEntries(
            (options || []).map(option => [option.value, state.getValue({ name: `${fieldId}|${option.value}` })])
          )
        : {}
    const otherOptionLabels = Object.fromEntries((options || []).map(option => [option.value, option.otherLabel]))

    const setOptionValue = (newValue: CustomValue, optionId: string) =>
      dispatch({ type: 'set value', field: { name: `${fieldId}|${optionId}` }, value: newValue })

    return {
      otherOptionValues,
      otherOptionLabels,
      otherOptionByOptionId,
      setOptionValue,
    }
  }, [dispatch, fieldId, options, state])
}
