import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'
import { useEffect, useState } from 'react'

export const useShouldSubmit = ({
  value,
  submitForm,
}: {
  value: ReturnType<typeof useSurveyValue>[0]
  submitForm: () => void
}) => {
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)

  /**
   * Submit form if value is set and user has given a value
   * With immer it's hard to set value and submit same time,
   * so we listen when value is set and then submit.
   *
   * We use shouldSubmit to wait user to interact with the form,
   * otherwise when user returns to form, this would submit form
   * immediately
   */
  useEffect(() => {
    if (shouldSubmit && typeof value === 'number') {
      submitForm()
    } else if (typeof value === 'number') {
      // Is user lands with value, we want to show submit button to let user know how to go forward
      setShowSubmit(true)
    }
  }, [value, submitForm, shouldSubmit])

  return {
    showSubmit,
    setShouldSubmit,
  }
}
