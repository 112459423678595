import { StepValuesIndex } from './api'
import { getStepValueByString } from './util'

interface ConditionBase {
  join?: 'and' | 'or'
  operator?: string
  test?: string
  field?: string
}

interface FieldCondition extends ConditionBase {
  field: string
  operator: string
}

interface GreaterThanCondition extends FieldCondition {
  operator: 'greaterthan'
  test: string
}

interface LessThanCondition extends FieldCondition {
  operator: 'lessthan'
  test: string
}

interface IsFalseCondition extends FieldCondition {
  operator: 'isfalse'
}

interface IsTrueCondition extends FieldCondition {
  operator: 'istrue'
}

interface IsNullCondition extends FieldCondition {
  operator: 'isnull'
}

interface NotNullCondition extends FieldCondition {
  operator: 'notnull'
}

interface IsCondition extends FieldCondition {
  operator: 'is'
  test: string
}

interface IsNotCondition extends FieldCondition {
  operator: 'isnot'
  test: string
}

interface CaptureSupportCondition extends ConditionBase {
  captureSupport: any
}

interface FeatureCondition extends ConditionBase {
  feature: string
}

export type Condition =
  | GreaterThanCondition
  | LessThanCondition
  | IsFalseCondition
  | IsTrueCondition
  | IsNullCondition
  | NotNullCondition
  | IsCondition
  | IsNotCondition
  | CaptureSupportCondition
  | FeatureCondition

export function groupConditions(conditions: Condition[]): Array<Condition[]> {
  const groups: Array<Condition[]> = []
  let group: Condition[] = []

  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i]
    const { join = 'and' } = condition

    if (i && join === 'and') {
      groups.push(group)
      group = []
    }

    group.push(condition)
  }

  if (group.length) groups.push(group)

  return groups
}

export function resolveCondition(condition: Condition, values: StepValuesIndex = {}, features: string[] = []): boolean {
  if ('captureSupport' in condition) {
    return true //Ignore legacy captureSupport
  }
  if ('feature' in condition) {
    return features.includes(condition.feature)
  }
  const value = getStepValueByString(values, condition.field)
  switch (condition.operator) {
    case 'greaterthan': {
      const test = condition.test
      if (typeof value === 'string') return value > test
      if (typeof value === 'number') return value > Number.parseFloat(test)
      return false
    }
    case 'lessthan': {
      const test = condition.test
      if (typeof value === 'string') return value < test
      if (typeof value === 'number') return value < Number.parseFloat(test)
      return false
    }
    case 'isfalse': {
      return value === false
    }
    case 'istrue': {
      return value === true
    }
    case 'is': {
      const test = condition.test
      return Array.isArray(value) ? value.indexOf(test) >= 0 : value === test
    }
    case 'isnot': {
      const test = condition.test
      return Array.isArray(value) ? value.indexOf(test) < 0 : value !== test
    }
    case 'isnull':
    case 'notnull': {
      const isNull =
        value == null ||
        value === undefined ||
        value == '' ||
        value === false ||
        (Array.isArray(value) && value.length === 0)
      return condition.operator === 'isnull' ? isNull : !isNull
    }
    default:
      return true
  }
}

export function resolveConditions(conditions: Condition[], values: StepValuesIndex, features: string[] = []): boolean {
  /**
   * Conditions are grouped to groups with 'OR' inside group and 'AND' between groups
   * [condition1]
   *  AND
   * [condition2 OR condition3]
   *  AND
   * [conditions4 OR condition5]
   */
  const groups = groupConditions(conditions)
  for (const group of groups) {
    if (!group.some(condition => resolveCondition(condition, values, features))) return false
  }
  return true
}
