import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

type CampaignContentProps = {
  content: string
}

export function CampaignContent(props: CampaignContentProps) {
  const theme = useTheme()
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: props.content }}
      sx={{
        ...theme.typography.body1,
        h1: theme.typography.h3,
        h2: theme.typography.h4,
        h3: theme.typography.h5,
        h4: theme.typography.h6,
        h5: theme.typography.h6,
        h6: theme.typography.h6,
        '& p': {
          m: 0,
        },
        '> *:first-child': {
          mt: 0,
        },
        ul: {
          pl: 2,
        },
        ol: {
          pl: 2,
        },
        img: {
          height: 'auto',
          borderRadius: 1,
        },
        a: {
          color: theme.palette.primary.main,
        },
      }}
    />
  )
}
