import { MultidropdownField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import React from 'react'
import Select from 'react-select'
import { useI18N } from '../../hooks/useI18n'
import { useOtherOptions } from '../../hooks/useOtherOptions'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { useTheme } from '../../hooks/useTheme'
import { InputField } from '../field/InputField'
import { dropdownStyles } from './Dropdown'

interface Props {
  field: MultidropdownField
}

const Divider = styled('div')`
  display: flex;
  flex-direction: column;
  .tm-input {
    margin-top: 8px;
  }
`

export function MultidropdownQuestion({ field }: Props): JSX.Element {
  const { t, lang } = useI18N()
  const theme = useTheme()
  const { options = [] } = field
  const [value = [], setValue] = useSurveyValue(field)
  const { otherOptionValues, setOptionValue } = useOtherOptions(field.name, field.options)
  if (!Array.isArray(value)) throw new Error('Multidropdown does not support value: ' + String(value))
  const translatedOptions = options.map(current => {
    return { value: current.value, label: current.label[lang] || '' }
  })
  const otherOptions = options.filter(option => value.includes(option.value) && option.isOtherOption)

  function selectOnChange(event: { label: string; value: string }[]) {
    setValue(event.map(o => o.value))
  }

  return (
    <>
      <Select
        styles={dropdownStyles(theme.primaryColor)}
        isMulti={true}
        options={translatedOptions}
        onChange={selectOnChange}
        defaultValue={translatedOptions.filter(o => value.indexOf(o.value) >= 0)}
        placeholder={t('dropdown.selectValue')}
        key={`${lang}-${field.name}`}
      />
      <Divider>
        {otherOptions.map(({ otherLabel = {}, value: optionValue }) => {
          const otherLabelStr = otherLabel[lang] || ''
          return (
            <InputField
              key={optionValue}
              placeholder={otherLabelStr}
              value={otherOptionValues[optionValue] as string}
              onValueChange={value => setOptionValue(value, optionValue)}
            />
          )
        })}
      </Divider>
    </>
  )
}
