import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'
import { TranslateFunction, useI18N } from '@tm/client-form/src/hooks/useI18n'
import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'
import { Field, FieldSetField } from '@tm/shared-lib/src/field'
import { ViewProps } from '../types'

import { CheckboxesInput } from './inputs/CheckboxesInput'
import { CheckboxInput } from './inputs/CheckboxInput'
import { ContentInput } from './inputs/ContentInput'
import { CsatInput } from './inputs/CsatInput'
import { DropdownInput } from './inputs/DropdownInput'
import { ImageInput } from './inputs/ImageInput'
import { NpsInput } from './inputs/NpsInput'
import { PrivacyPolicyInput } from './inputs/PrivacyPolicyInput'
import { PublicConsentInput } from './inputs/PublicConsentInput'
import { RadiosInput } from './inputs/RadiosInput'
import { RangeInput } from './inputs/RangeInput'
import { StarsInput } from './inputs/StarsInput'
import { TextAreaInput } from './inputs/TextAreaInput'
import { TextFieldInput } from './inputs/TextFieldInput'
import { InputProps } from './inputs/types'
import { VideoInput } from './inputs/VideoInput'
import { VideoReviewIntro } from './inputs/VideoInput/VideoReviewIntro'

type RenderFieldProps = ViewProps & {
  field: Field
}

const getFieldLabel = (field: Field, lang: string, t: TranslateFunction) => {
  if (field.type === 'content') return null

  const isRequired = field.validation?.includes('required')

  if ('label' in field && field.label) {
    return `${field.label[lang] || ''}${isRequired ? ' *' : ''}`
  }
  if ('labelKey' in field && field.labelKey) {
    return `${t(field.labelKey)}${isRequired ? ' *' : ''}`
  }
  return null
}

const getFieldDescription = (field: Field, lang: string, t: TranslateFunction) => {
  if (field.type === 'content') return null

  if ('description' in field && field.description) {
    return field.description[lang]
  }
  if ('descriptionKey' in field && field.descriptionKey) {
    return t(field.descriptionKey)
  }
  return null
}

function RenderInput(props: RenderFieldProps) {
  const { field, state, isSubmitting, dispatch, validationErrors } = props
  const lang = state.lang
  const [value, setValue] = useSurveyValue(field)

  const inputProps: Omit<InputProps<null>, 'field'> = {
    lang,
    value,
    onChange: setValue,
    isSubmitting,
  }

  switch (field.type) {
    case 'checkbox':
      return <CheckboxInput {...inputProps} field={field} />
    case 'checkboxes':
      return <CheckboxesInput {...inputProps} field={field} />
    case 'comment':
      return <TextAreaInput {...inputProps} field={field} />
    case 'content':
      // Legacy feature to show video intro in generated page
      if (field.videoIntro) return <VideoReviewIntro />
      return <ContentInput field={field} lang={lang} />
    case 'dropdown':
      return <DropdownInput {...inputProps} field={field} />
    case 'email':
      return <TextFieldInput {...inputProps} field={field} />
    case 'image':
      return <ImageInput {...inputProps} field={field} />
    case 'links':
      return <div>Render links</div>
    case 'multidropdown':
      return <DropdownInput {...inputProps} field={field} />
    case 'nps':
      return <NpsInput {...inputProps} field={field} />
    case 'csat':
      return <CsatInput {...inputProps} field={field} />
    case 'privacy_policy':
      return (
        <PrivacyPolicyInput
          {...inputProps}
          field={field}
          state={state}
          dispatch={dispatch}
          validationErrors={validationErrors}
        />
      )
    case 'public_consent':
      return (
        <PublicConsentInput
          {...inputProps}
          field={field}
          state={state}
          dispatch={dispatch}
          validationErrors={validationErrors}
        />
      )
    case 'radios':
      return <RadiosInput {...inputProps} field={field} />
    case 'range':
      return <RangeInput {...inputProps} field={field} />
    case 'stars':
      return <StarsInput {...inputProps} field={field} />
    case 'textarea':
      return <TextAreaInput {...inputProps} field={field} />
    case 'textfield':
      return <TextFieldInput {...inputProps} field={field} />
    case 'third-party-collect':
      return <div>Render third-party-collect</div>
    case 'video':
      return <VideoInput field={field} />
    case 'widget':
      return <div>Render widget</div>
    default:
      return <div>Unknown field type {field.type}</div>
  }
}

function RenderFieldset({ field, ...props }: Omit<RenderFieldProps, 'field'> & { field: FieldSetField }) {
  const { fields } = field

  return (
    <CampaignGroup>
      {fields?.map((field, index) => (
        <RenderField key={`${index}-${field.name}`} {...props} field={field} inFieldset />
      ))}
    </CampaignGroup>
  )
}

export function RenderField(props: RenderFieldProps & { inFieldset?: boolean }) {
  const { state, field, validationErrors, inFieldset } = props
  const lang = state.lang
  const { t } = useI18N()
  const label = getFieldLabel(field, lang, t)
  const description = getFieldDescription(field, lang, t)
  const path = field.system ? field.name : 'custom.' + field.name
  const errors = validationErrors[path] || []

  if (field.hidden) return null

  return (
    <Stack gap={inFieldset ? 1 : 2}>
      {label || description ? (
        <Stack gap={1}>
          {label && (
            <Typography
              component="label"
              htmlFor={`f-${field.name}`}
              variant={inFieldset ? 'subtitle2' : 'subtitle1'}
              sx={{
                cursor: 'pointer',
              }}>
              {label}
            </Typography>
          )}
          {description && (
            <Typography variant={inFieldset ? 'body2' : 'body1'} color="text.secondary">
              {description}
            </Typography>
          )}
        </Stack>
      ) : null}
      {field.type === 'fieldset' ? (
        <RenderFieldset {...props} field={field} />
      ) : (
        <CampaignGroup plain={inFieldset || field.type === 'checkbox'}>
          <RenderInput {...props} />
        </CampaignGroup>
      )}
      {errors.map((error, index) => (
        <Typography key={index} variant="body1" color="error">
          {t(error)}
        </Typography>
      ))}
    </Stack>
  )
}
