import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CampaignContent } from '@tm/client-form/src/components/campaign/CampaignContent'
import { ThirdPartyCollectSource } from '@tm/types/survey/third-party-collect'
import { PlatformView } from '../Review/PlatformView'

export type ThanksViewProps = {
  title: string
  text: string
  sources?: ThirdPartyCollectSource[]
  lang: string
  autoRedirectEnabled?: boolean
  preview?: boolean
}

export function ThanksView({ title, text, sources, lang, preview = false, autoRedirectEnabled }: ThanksViewProps) {
  const urlSearchParams = new URLSearchParams(location.search)
  const inPreview = preview || urlSearchParams.get('preview')
  const noRedirect = !autoRedirectEnabled || !!inPreview
  return (
    <Stack sx={{ textAlign: 'center', maxWidth: 480, mx: 'auto', gap: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      <CampaignContent content={text} />
      {!!sources && <PlatformView sources={sources} lang={lang} noRedirect={noRedirect} />}
    </Stack>
  )
}
