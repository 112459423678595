import { RadiosField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useOtherOptions } from '../../hooks/useOtherOptions'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { InputField } from '../field/InputField'
import { DotIcon } from '../Icon'

const RadioButton = styled('button')(({ theme }) => theme.styles.radioButton)
const RadioIconWrapper = styled('div')(({ theme }) => theme.styles.radioIconWrapper)
const RadioIcon = styled('div')(({ theme }) => theme.styles.radioIcon)
const RadioIconSvg = styled(DotIcon)(({ theme }) => theme.styles.radioIconSvg)
const RadioLabel = styled('div')(({ theme }) => theme.styles.radioLabel)
const Divider = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .tm-input {
    margin-bottom: 8px;
  }
`

interface Props {
  field: RadiosField
}

export function RadiosQuestion({ field }: Props): JSX.Element {
  const { options = [] } = field
  const { lang } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const { otherOptionValues, setOptionValue } = useOtherOptions(field.name, field.options)

  return (
    <div className="tm-input tm-input--radios">
      {options.map(({ value: optionValue, label = {}, isOtherOption = false, otherLabel = {} }) => {
        const classes = ['tm-radio']
        const isChecked = optionValue === value

        if (isChecked) classes.push('tm-radio--selected')

        const labelStr = label[lang] || ''
        const otherLabelStr = otherLabel[lang] || ''

        return (
          <Divider key={optionValue}>
            <RadioButton
              className={classes.join(' ')}
              onClick={e => {
                e.preventDefault()
                setValue(optionValue)
              }}>
              <RadioIconWrapper className="tm-radio-icon-wrapper">
                <RadioIcon className="tm-radio-icon">{isChecked ? <RadioIconSvg /> : null}</RadioIcon>
              </RadioIconWrapper>
              <RadioLabel className="tm-radio-label">{labelStr}</RadioLabel>
            </RadioButton>
            {isChecked && isOtherOption ? (
              <InputField
                placeholder={otherLabelStr}
                value={otherOptionValues[optionValue] as string}
                onValueChange={value => setOptionValue(value, optionValue)}
              />
            ) : null}
          </Divider>
        )
      })}
    </div>
  )
}
